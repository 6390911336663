<style>
/* Set the page size to A4 and the orientation to landscape */
@page {
    size: A4 landscape;
}

/* Set the font size to 12px for printing */
@media print {
    body {
        font-size: 12px;
        font-family: helvetica;
    }
}

#logo {
    position: absolute;
    top: 2px;
    right: 2px;
    width: 200px;
    height: 100px;
}

#title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
}

table {
    font-family: helvetica;
    border-collapse: collapse;
    width: 100%;
}

#no-border {
    border: none;
}

td,
th {
    border: 2px solid #000000;
    text-align: left;
    padding: 8px;
}

.remove_border tr th {
    border: none;
}

#table-list {
    margin-top: 80px;
}

#table-list2 {
    margin-top: 10px;
}

#info {
    margin-top: 20px;
    width: 40%;
    float: left;
    margin-bottom: 20px;
}

.space-for-logo {
    width: 200px;
    height: 100px;
}
</style>

<template>
    <div>
        <!-- <img :src="logoSDN" id="logo" /> -->
        <p id="title">SDN-FRM-LOG.02-005</p>
        <p id="title">SURAT PERMINTAAN BARANG (SPB)</p>
        <div class="space-for-logo"></div>
        <div id="info">
            <table class="remove_border">
                <tr>
                    <th>No. SPB</th>
                    <th>:</th>
                    <th>KODE CABANG / NO.URUT</th>
                </tr>
                <br>
                <tr>
                    <th>NAMA SALESMAN</th>
                    <th>:</th>
                    <th>{{ selectedSales.label }}</th>
                </tr>
                <tr>
                    <th>CABANG</th>
                    <th>:</th>
                    <th>{{ selectedWarehouse.label }}</th>
                </tr>
                <tr>
                    <th>KEPERLUAN</th>
                    <th>:</th>
                    <th></th>
                </tr>
                <tr>
                    <th>STORE LOC</th>
                    <th>:</th>
                    <th>{{ selectedTerritory.label }}</th>
                </tr>
                <tr>
                    <th>TANGGAL AMBIL</th>
                    <th>:</th>
                    <th>{{ formatDate(loadingDate) }}</th>
                </tr>
                <tr>
                    <th>TANGGAL KEMBALI</th>
                    <th>:</th>
                    <th></th>
                </tr>
            </table>
        </div>
        <table id="table-list">
            <thead>
                <tr>
                    <th width="65%">SKU Description</th>
                    <th width="20%">HU</th>
                    <th width="15%">Qty</th>
                </tr>
            </thead>

            <tbody>
                <tr v-for="(skuLine, index) in skuLines" :key="index" style="padding-bottom: 5px">
                    <td class="td vs-table--td" style="padding: 5px">{{ skuLine.selected_sku.label }}</td>
                    <td class="td vs-table--td" style="padding: 5px">{{ skuLine.selected_hu }}</td>
                    <td class="td vs-table--td" style="padding: 5px">{{ skuLine.qty }}</td>
                </tr>
            </tbody>
        </table>
        <table id="table-list2">
            <thead>
                <tr>
                    <th style="text-align: center">Diajukan Oleh,</th>
                    <th colspan="3" style="text-align: center">Disetujui Oleh,</th>
                    <th style="text-align: center">Dibawa Oleh,</th>
                    <th style="text-align: center">Diserahkan Oleh,</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="td vs-table--td" style="text-align: center">Salesman <br><br><br><br><br> ( {{ selectedSales.label }} )</td>
                    <td class="td vs-table--td" style="text-align: center">BAM/BAS/OS <br><br><br><br><br> ( {{ selectedSales.label }} )</td>
                    <td class="td vs-table--td" style="text-align: center">BLC/WHS Supervisor <br><br><br><br><br> ( {{ selectedSales.label }} )</td>
                    <td class="td vs-table--td" style="text-align: center">BM/OM <br><br><br><br><br> ( {{ selectedSales.label }} )</td>
                    <td class="td vs-table--td" style="text-align: center">Salesman <br><br><br><br><br> ( {{ selectedSales.label }} )</td>
                    <td class="td vs-table--td" style="text-align: center">PIC Logistik <br><br><br><br><br> ( {{ selectedSales.label }} )</td>
                </tr>
            </tbody>
        </table>
        <table id="table-list2">
            <thead>
                <tr>
                    <th width="30%" style="text-align: center">Dikembalikan Oleh,</th>
                    <th width="30%" style="text-align: center">Diterima Oleh,</th>
                    <th width="40%" style="text-align: center">Catatan</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style="text-align: center">Salesman <br><br><br><br><br> ( {{ selectedSales.label }} )</td>
                    <td style="text-align: center">PIC Logistik <br><br><br><br><br> ( {{ selectedSales.label }} )</td>
                    <td style="text-align: center"></td>
                </tr>
            </tbody>
        </table>
        <div id="info">
            <table class="remove_border">
                <tr>
                    <th>No. Document</th>
                    <th>:</th>
                    <th>{{ code }}</th>
                </tr>
                <tr>
                    <th>No. Revisi</th>
                    <th>:</th>
                    <th>01</th>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import moment from "moment";
// import moment from "moment-timezone";
// import logoSDN from "../../../assets/images/logo/logo-sdn.png";

export default {
    name: "PrintPdf",
    data() {
        return {
            id: null,
            readonly: true,
            code: null,
            selectedTerritory: null,
            selectedWarehouse: null,
            selectedSales: null,
            loadingDate: null,
            skuLines: [
                {
                    canvas_item_request_line_id: 0,
                    selected_sku: null,
                    desc_selected_sku: "",
                    selected_hu: null,
                    qty: 0
                }
            ],
            // logoSDN: logoSDN,
            showInfo: false,
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.print();
        }
    },
    methods: {
        tableDefaultState() {
            return {
                data: [],
            };
        },
        async print() {
            await this.getData()
            const printOptions = {
                silent: true, // Non-interactive mode
                deviceName: 'EPSON LX-300+II ESC/P',
                pageSize: 'A4',
                marginsType: 0,
                landscape: false,
            };
            await window.print(printOptions)
        },
        async getData() {
            this.$vs.loading();
            let resp = await this.$http.get(
                "/api/v1/canvas/edit/" + this.id
            );
            if (resp.code == 200) {
                this.code = resp.data.code;
                this.selectedWarehouse = resp.data.selected_warehouse;
                this.selectedTerritory = resp.data.selected_territory;
                this.selectedSales = resp.data.selected_sales;
                this.loadingDate = moment(resp.data.loading_date).toDate();
                this.skuLines = resp.data.sku_lines;
                this.$vs.loading.close();
            } else {
                this.$vs.loading.close();
            }
        },
    },
    computed: {
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('YYYY-MM-DD');
            };
        },
    },
}
</script>